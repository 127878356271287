<script>
  import { get, call } from 'vuex-pathify'
  import screen from '@/helpers/screen'
  import { events } from '@/helpers/gtm'

  export default {
    data() {
      return {
        phoneNumber: '',
        optIn: false,
        firstName: this.$store.get('account/firstName'),
        lastName: this.$store.get('account/lastName'),
        error: null,
        submitting: false,
        confirmed: false,
        confirmedTimeout: null,
      }
    },
    computed: {
      ...get('customize', ['isWaitlistAndIsQuarterly', 'isSelectedSubscriptionQuarterly']),
      ...get('account', ['findSubscriptionById']),
      ...get('config', ['currentBox']),
      screen,
      buttonDisabled() {
        return !this.optIn || this.phoneNumber.length !== 12
      },
      subscriptionIsAlltruePlus() {
        return this.findSubscriptionById(this.$route.params.subscriptionId)?.isAlltruePlus
      },
      customizationIsOpen() {
        return this.isSelectedSubscriptionQuarterly
          ? this.currentBox?.choiceQuarterlyEnabled
          : this.currentBox?.choiceAnnualEnabled
      },
    },
    methods: {
      ...call('account', ['updateAccountInfo']),
      routeToNextPage() {
        if (this.isWaitlistAndIsQuarterly) {
          this.$navigate({
            name: 'SubscribeWelcome',
            params: {
              ...this.$route.params,
            },
          })
        } else if (this.subscriptionIsAlltruePlus) {
          this.$navigate({
            name: 'SubscribeTshirt',
            params: {
              ...this.$route.params,
            },
          })
        } else {
          const startDate = this.isSelectedSubscriptionQuarterly
            ? this.currentBox.choiceQuarterlyStartDate
            : this.currentBox.choiceAnnualStartDate
          const startDateIsImminent = startDate
            ? new Date() < new Date(startDate.replace(/-/g, '/'))
            : null
          if (!this.customizationIsOpen && !startDateIsImminent) {
            this.$navigate({ name: 'SubscribeWelcome' })
          } else {
            const type = this.customizationIsOpen ? 'customizeStart' : 'customizeComingSoon'
            this.$navigate({
              name: 'SubscribeInterstitial',
              params: {
                subscribe: 'subscribe',
                type,
                subscriptionId: this.$route.params.subscriptionId,
              },
            })
          }
        }
      },
      skipToNextPage() {
        events.log({ name: 'subscribe skip phone number' })
        this.routeToNextPage()
      },
      async submit() {
        try {
          this.submitting = true
          await this.updateAccountInfo({
            firstName: this.firstName,
            lastName: this.lastName,
            phoneNumber: this.phoneNumber,
          })
          this.confirmed = true
          clearTimeout(this.confirmedTimeout)
          events.log({ name: 'subscribe submitted phone number' })
          this.confirmedTimeout = setTimeout(() => {
            this.routeToNextPage()
          }, 2000)
        } catch (error) {
          this.error = error.data.message
        } finally {
          this.submitting = false
        }
      },
    },
  }
</script>

<template>
  <component :is="screen.md ? 'BaseContainer' : 'div'" max-width="xs">
    <div v-if="!confirmed" class="flex flex-col items-center py-6 px-4 md:px-0">
      <BasePanelWarning v-if="error" class="mb-6">
        {{ error }}
      </BasePanelWarning>
      <h1 class="font-heading text-4xl text-center leading-tighter">
        Join our <br class="hidden md:block" />
        Insiders Group
      </h1>
      <img
        src="/static/illustrations/IllustrationInsiders.png"
        class="m-auto mt-4 mb-6 max-h-30"
        alt=""
      />
      <div class="w-full">
        <BaseForm v-slot="{ inputsEnabled }">
          <BaseInputPhoneNumber
            v-model="phoneNumber"
            type="tel"
            :disabled="!inputsEnabled"
            class="mb-8"
          />
          <BaseCheckbox v-model="optIn">
            <p class="text-xs leading-snug">
              Sign up to receive text notifications about shipping updates, special offers, and new
              launches!
            </p>
            <p class="mt-4 text-3xs leading-snug text-gray-opacity68">
              By checking this box, you agree to receive recurring automated promotional and
              personalized marketing text messages (e.g. cart reminders) from Alltrue at the cell
              number used when signing up. Consent is not a condition of any purchase. Reply HELP
              for help and STOP to cancel. Msg frequency varies. Msg and data rates may apply.
              <span class="font-semibold">
                <BaseLinkStyled :to="{ name: 'PageTermsOfUse' }" target="_blank"
                  >View Terms</BaseLinkStyled
                >
                <span class="font-normal">&</span>
                <BaseLinkStyled :to="{ name: 'PagePrivacy' }" target="_blank"
                  >Privacy</BaseLinkStyled
                >
              </span>
            </p>
          </BaseCheckbox>
          <BaseButton v-if="screen.md" :disabled="buttonDisabled" class="my-6" @click="submit">
            Submit
          </BaseButton>
        </BaseForm>
      </div>

      <BaseLinkStyled font-size="xs" class="mt-14 md:mt-0 mb-20" @click="skipToNextPage">
        No thanks, maybe later
      </BaseLinkStyled>
      <Portal to="fixedBottom">
        <BaseTransitionFixedBottom>
          <div class="md:hidden bg-primary">
            <BaseButton :disabled="buttonDisabled" @click="submit"> Submit </BaseButton>
          </div>
        </BaseTransitionFixedBottom>
      </Portal>
    </div>

    <div
      v-else
      :class="`font-heading font-medium text-4xl flex flex-col items-center ${
        isWaitlistAndIsQuarterly ? 'mt-32' : 'mt-22'
      }`"
    >
      <BaseIcon :size="9" class="text-primary">
        <IconCheckmarkCircle />
      </BaseIcon>
      Confirmed!
    </div>

    <!-- Loading spinner -->
    <BaseSpinner v-if="submitting" overlay="fixed" overlay-color="white" class="z-10" />
  </component>
</template>
